<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      style="background-color: #00b0ff;border: none;"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg svg-icon-white">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text text-white">Daftar <b>Hak Akses User</b></div>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3 mb-2">
              <div class="col-md-8">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <b-tabs>
              <b-tab title="Super Admin" @click="filterUserByRole(1)" active></b-tab>
              <div v-for="role in roles" :key="role.id">
                <b-tab
                  :title="role.name"
                  v-if="role.id !== 1"
                  @click="filterUserByRole(role.id)"
                ></b-tab>
              </div>
            </b-tabs>
            <!-- Tabel Role di sebelah kiri -->
            <b-table striped hover class="mt-3" :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover.right
                  :title="data.item.id"
                  @click="getAccessByUser(data.item.id)"
                >
                  <i class="fas fa-eye px-0"></i>
                </b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="getAccess(data.item.id)"
                >
                  Pilih
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>

      <!-- Tabel Hak Akses di sebelah kanan-->
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <h5>Hak Akses</h5>
                <h6>{{ username }}</h6>
              </div>
            </div>

            <div class="row justify-content-start mt-3">
              <div class="col-md-6">
                <b-form-input
                  id="filter-input"
                  v-model="filterAccess"
                  type="search"
                  placeholder="Cari Hak Akses"
                ></b-form-input>
              </div>
            </div>

            <!-- Table -->
            <b-table
              hover
              class="mt-3"
              :items="access_lists_display"
              :fields="fieldAccess"
              id="my-table"
              :filter="filterAccess"
              @filtered="onFiltered"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(code)="data">
                <span v-if="data.item.parent_id == 0">
                  <b>{{ data.item.name }}</b>
                </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.code }}
                </span>
              </template>
              <template #cell(name)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                  {{ data.item.name }}
                </span>
              </template>
              <template #cell(actions)="data">
                <span v-if="data.item.parent_id == 0"> </span>
                <span v-if="data.item.parent_id !== 0">
                <!-- <b-button
                    size="sm"
                    v-if="data.item.access_status == true && !data.item.access_status_position"
                    @click="detachUser(data.item.id)"
                    class="mr-1 btn-success"
                  ><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    v-if="data.item.access_status_position == true && data.item.access_status == true"
                    class="mr-1 btn-secondary"
                    disabled
                  ><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    v-if="!data.item.access_status"
                    @click="attachUser(data.item.id)"
                    class="mr-1 btn-danger"
                  ><i class="fas fa-times px-0"></i></b-button> -->
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    :disabled="btnAccess ? false : true"
                    v-if="data.item.access_status == true && !data.item.access_status_role"
                    @click="detachUser(data.item.id)"
                    ><i class="fas fa-check px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-secondary"
                    v-if="data.item.access_status_role == true && data.item.access_status == true"
                    disabled
                  ><i class="fas fa-check px-0"></i></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    :disabled="btnAccess ? false : true"
                    v-if="!data.item.access_status"
                    @click="attachUser(data.item.id)"
                    ><i class="fas fa-times px-0"></i
                  ></b-button>
                </span>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import api from "@/core/services/api.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
        role_id: "1",
      },
      filterAccess: "",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      perPageAccess: 10,
      currentPageAccess: 1,
      totalRowsAccess: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldAccess: [
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Fitur / Modul Sistem",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Table Items Access List
      access_lists: [],
      access_lists_children: [],
      access_lists_display: [],
      // roles
      roles: [],
      //
      access_by_user: [],
      // user name
      username: "",
      user_id: "",
      // access
      btnAccess: false
    };
  },

  methods: {
    resetArrayOnNew() {
      this.access_lists = [];
      this.access_lists_children = [];
      this.access_lists_display = [];
      this.access_by_user = [];
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAccess = filteredItems.length;
      this.currentPage = 1;
    },

    // function tabel dokter di sebelah kiri
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      for(let a = 0; a < response.data.length; a++){
        response.data[a].number = a + 1
      }
      
      let pagination = response.meta.pagination;
      this.items = response.data;
      this.totalRows = pagination.total;
    },

    filterByName() {
      this.pagination();
    },

    filterUserByRole(id) {
      this.filter.role_id = id;
      //("jalan filter role id", this.filter.role_id);
      this.pagination();
    },

    async getRoles() {
      let response = await module.paginate(
        "api/roles",
        `?page=${this.currentPage}&page_size=${this.perPage}`
      );
      this.roles = response.data;
    },

    // function tabel hak akses sebelah kanan
    async getAccessList() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/access-lists",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.access_lists = response.data;
      //("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
          }
        }
      }
      //("hasil", this.access_lists_display);
      this.totalRowsAccess = this.access_lists_display.length;
    },

    getAccess(id) {
      this.user_id = id
      this.resetArrayOnNew();
      this.getAccessByUser(id);
    },

    async getAccessByUser(id) {
      // this.resetArrayOnNew();

      let access_by_user = await module.getAccessByUser("api/users/" + id);
      console.log('access by user', access_by_user)
      this.username = access_by_user.data.name;
      // this.user_id = access_by_user.data.id;
      let access_right = access_by_user.meta.access_right_display;
      console.log('access right user', access_right)
      //("acc_by_user", access_by_user);
      
      // get access by position
      if (access_by_user.data.role_id) {
        let access_by_role = await module.getAccessByUser("api/roles/" + access_by_user.data.role_id);

        // this.username = access_by_user.data.name
        var access_right_role = access_by_role.meta.access_right_display
        console.log('access right role', access_right_role)
        //("acc_by_position", access_by_role);
      }


      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "api/access-lists",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.access_lists = response.data;
      //("default", this.access_lists);

      this.access_lists_display = [];
      let a, b, c, d, e;
      for (a = 0; a < this.access_lists.length; a++) {
        this.access_lists_children = response.data[a].children;
        this.access_lists_display.push(this.access_lists[a]);
        for (b = 0; b < this.access_lists_children.length; b++) {
          this.access_lists_display.push(this.access_lists_children[b]);
          for (c = 0; c < this.access_lists_display.length; c++) {
            if (this.access_lists_display[c].parent_id !== 0) {
              this.access_lists_display[c].number = "-";
            }
            for (d = 0; d < access_right.length; d++) {
              if (this.access_lists_display[c].code == access_right[d]) {
                this.access_lists_display[c].access_status = true;
              }
            }
            if (access_by_user.data.role_id) {
              for (e = 0; e < access_right_role.length; e++) {
                if (this.access_lists_display[c].code == access_right_role[e]) {
                  this.access_lists_display[c].access_status_role = true
                  this.access_lists_display[c].access_status = true
                }
              }
            }
          }
        }
        //("hasil akhir", this.access_lists_display);
      }
    },

    async attachUser(id) {
      let targetUser = this.user_id;
      let done = await api
        .post("api/access-lists/" + id + "/users/" + this.user_id + "/attach")
        .then((response) => {
          //("attach role", response);
          this.getAccessByUser(this.user_id);
          if (targetUser == getUser().id) {
            localStorage.setItem("access_right", response.data.access_right);
            localStorage.setItem(
              "access_right_display",
              JSON.stringify(response.data.access_right_display)
            );
            return true;
          }
        });
      if (done) {
        this.$root.$emit("setMenuActive");
        this.$root.$emit("UpdateSideMenu");
      }
    },

    async detachUser(id) {
      let targetUser = this.user_id;
      //("detach");
      let done = await api
        .delete("api/access-lists/" + id + "/users/" + this.user_id + "/detach")
        .then((response) => {
          //("detach role", response);
          this.getAccessByUser(this.user_id);
          if (targetUser == getUser().id) {
            localStorage.setItem("access_right", response.data.access_right);
            localStorage.setItem(
              "access_right_display",
              JSON.stringify(response.data.access_right_display)
            );
            return true;
          }
        });
      if (done) {
        this.$root.$emit("setMenuActive");
        this.$root.$emit("UpdateSideMenu");
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6004") {
          this.btnAccess = true
        }
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Hak Akses User" }]);
    // Get Data
    // this.getAccessList();
    this.pagination();
    this.getRoles();
    this.checkAccess();
  },
};
</script>
